<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-ground-banner with-route-bar">
      <div class="items">
        <div
          class="item"
          :style="{
            'background-image': `url(${require('@/assets/img/fo/bg-5.jpg')})`
          }"
        >
          <span v-html="$t('public.food_menu_header')"></span>
        </div>
      </div>
    </section>

    <section class="section section-localnav">
      <nav class="tabnav">
        <ul class="tabnav-items">
          <li class="tabnav-offset-item"><div></div></li>
          <li
            class="tabnav-item tabnav-first-item"
            :class="{ active: type === 'economy' }"
          >
            <em></em>
            <a
              @click.prevent="type = 'economy'"
              href="#"
              class="tabnav-link"
              v-html="$t('public.food_menu_economy')"
            ></a>
          </li>
          <li
            class="tabnav-item tabnav-last-item"
            :class="{ active: type === 'business' }"
          >
            <em></em>
            <a
              @click.prevent="type = 'business'"
              href="#"
              class="tabnav-link"
              v-html="$t('public.food_menu_business')"
            ></a>
          </li>
          <li class="tabnav-offset-item"><div></div></li>
        </ul>
      </nav>
    </section>

    <section class="section section-inner" v-if="type === 'economy'">
      <div class="container m-container-only-s">
        <div class="content-cols">
          <div class="left-col">
            <img src="@/assets/img/hero-food.png" alt="" />
            <p v-html="$t('public.food_menu_economy_text_1')"></p>
          </div>
          <div class="legals">
            <p v-html="$t('public.food_menu_economy_text_2')"></p>
          </div>
          <div class="right-col">
            <h1>
              {{ $t("public.food_menu_economy_text_3")
              }}<span class="sup">*</span>
            </h1>

            <p v-html="$t('public.food_menu_economy_text_4')"></p>

            <ol>
              <li>
                <h3 v-html="$t('public.food_menu_economy_text_5')"></h3>
                <p>
                  {{ $t("public.food_menu_economy_text_6") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img src="@/assets/img/food-img-1.png" class="screen" alt="" />
                <p v-html="$t('public.food_menu_economy_text_7')"></p>
              </li>
              <li>
                <h3 v-html="$t('public.food_menu_economy_text_8')"></h3>
                <img
                  src="@/assets/img/food-img-2.png"
                  class="screen small"
                  alt=""
                />
              </li>
              <li>
                <h3 v-html="$t('public.food_menu_economy_text_9')"></h3>
                <img
                  src="@/assets/img/food-img-3.png"
                  class="screen small"
                  alt=""
                />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-inner" v-else-if="type === 'business'">
      <div class="container m-container-only-s">
        <div class="content-cols">
          <div class="left-col">
            <img src="@/assets/img/hero-business.png" alt="" />
            <p v-html="$t('public.food_menu_business_text_1')"></p>
          </div>
          <div class="legals">
            <p v-html="$t('public.food_menu_business_text_2')"></p>
          </div>
          <div class="right-col">
            <h1>
              {{ $t("public.food_menu_business_text_3")
              }}<span class="sup">*</span>
            </h1>

            <p v-html="$t('public.food_menu_business_text_4')"></p>

            <ol>
              <li>
                <h3 v-html="$t('public.food_menu_business_text_5')"></h3>
                <p>
                  {{ $t("public.food_menu_business_text_6") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img
                  src="@/assets/img/business-img-1.png"
                  class="screen"
                  alt=""
                />
                <p v-html="$t('public.food_menu_business_text_7')"></p>
              </li>
              <li>
                <h3 v-html="$t('public.food_menu_business_text_8')"></h3>
                <img
                  src="@/assets/img/business-img-2.png"
                  class="screen small"
                  alt=""
                />
              </li>
              <li>
                <h3 v-html="$t('public.food_menu_business_text_9')"></h3>
                <p v-html="$t('public.food_menu_business_text_10')"></p>
                <p v-html="$t('public.food_menu_business_text_11')"></p>
                <img
                  src="@/assets/img/business-img-3.png"
                  class="screen"
                  alt=""
                />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Food_menuPublic",
  title() {
    return this.$t("pages.food");
  },
  data: () => {
    return {
      items: {},
      type: "economy",
      selectedFoodMenu: {}
    };
  },
  computed: {
    ...mapState("user", {
      userState: state => state.user
    })
  },
  methods: {
    ...mapActions("order", {
      actionIndex: "index"
    }),
    ...mapActions("order", {
      setOrderFoodMenu: "setOrderFoodMenu"
    })
  }
};
</script>

<style scoped>
.disabled-custom {
  color: black;
  font-size: 16px;
  background: #bababa;
  border-radius: 16px;
  text-align: center;
  width: 100%;
  padding: 8px 10px;
  border: none;
}
.input-radio input:checked ~ .checkmark.disabled:after {
  background: #8d8d8d;
}
.input-checkbox input:checked ~ .checkmark.disabled:after {
  background: url(../../scss/i/icon-check-gray.svg) center no-repeat;
}
.food-menu .eating-time .item strong.disabled {
  color: #8d8d8d;
}
</style>
